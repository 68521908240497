:root {
  --main: #30356d;
  --darkBlue: #30356d;
  --vicGreen: #43fa85;
  --bg: #ffcfde;
  /* --bg: #f8d1c5; */
  --text: #444444;
  --red: #ff7730;
  --darkGreen: #007966;
  --yellow: #fce486;
  --lightYellow: #faebb4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:'Source Code Pro', monospace;
  font-size: 24px;
  line-height: 34px;
}


html,
body {
  /* overflow: hidden; */
  background-color: var(--bg);
  /* height: 2000px; */
}
.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  height: 100vh;
}
.loadingGroup{
  position: absolute;
  top: 40%;
  left: 50%;
  width: 70%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.loading-container {
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7 );
  overflow: hidden;
  transition: background-color 0.5s;
  will-change: background-color;
}
.loading-container.ended {
  background-color: rgba(255, 255, 255, 0);
}
.loading-bar {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 6px;
  color: var(--darkBlue);
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: var(--main);
  text-align: center;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
  will-change: transform;
}
.loadingPercent{
  padding-top: 10px;
  color: var(--darkBlue);
  font-size: 30px;
}
.progressMasterCont{
  position: fixed;
  height: 20px;
  bottom: 80px;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  /* background-color: yellow; */
  opacity:0;
  
}
.progressContainer{
  box-sizing: border-box;
  position: fixed;
  top: 5px;
  width: 100%;
  height: 10px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  background-color: #00000044;
  will-change: height;
  will-change: transform;
  border-radius: 6px;
  /* opacity:0; */
  
  
}

.progressBar{
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 10px;
  padding: 0px;
  margin: 0px;
  border-radius: 6px;
  transform: translateX(-100%);
  transform-origin: top left;
  z-index: 4;
  /* transition: transform 0.5s; */
  will-change: transform;
  background-color: #43fa85;
 
}
.playhead{
  position: fixed;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  background-color:  var(--darkBlue);
  z-index: 6;
  transform: translateX(50%);
}
.milestone{
  box-sizing: border-box;
  position: fixed;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  /* background-color: var(--darkBlue); */
  border: 3px solid var(--darkBlue);
  z-index: 3;
  transform: translateX(-50%);
}
.mOne{
  left: 7.8%;
  transform: translateX(-50%);
}
.mTwo{
  left: 52.5%;
  /* transform: translateX(-50%); */
}
.mThree{
  left: 62.5%;
  transform: translateX(0%);
}
.mFour{
  left: 67.5%;
  transform: translateX(0%);
}
.mFive{
  left: 72.6%;
  transform: translateX(0%);
}
.mEnd{
  left: 100%;
  transform: translateX(-13px);
}

.fixedContainer{
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 5;
  padding-top:0px;
  font-family: Helvetica, Arial, sans-serif;
  color:var(--darkBlue);
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center; */
  transition: transform .5s ease-in-out;
}
.infoPage{
  box-sizing: border-box;
  position: fixed;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:50px 50px 50px 50px;
  overflow: hidden;
  background-color: #ffffff;
}
.infoGroup{
  box-sizing: border-box;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Helvetica, sans-serif;
  font-size: 22px;
  color: var(--darkBlue);
  
}
.infoGroupRight{
  box-sizing: border-box;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-family: Helvetica, sans-serif;
  font-size: 22px;
  color: var(--darkBlue);
}
.subGroup{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  
  /* padding:20px; */
  height: 100%!important;
}
.subGroupH{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, sans-serif;
  font-size: 22px;
  color: var(--darkBlue);
  padding-top:30px;
  
}
.pageTitleImg{
  box-sizing: border-box;
  width: 70%;
  padding-bottom: 20px;
  
}
.infoParagraph{
  width: 100%;
  margin-top: 25px;
  padding:0px 0px 0px 0px;
  text-align: justify;
}
.infoImage{
  width: 100%;
}
.videoFullScreen{
  width: 50%;
  border-radius: 14px;
  overflow: hidden;
}

.pageOne{
  top: 0px;
  left: 0px;
  transform:translateX(-100%);
}
.pageTwo{
  top: 0px;
  left: 50%;
  transform:translateX(100%);
}
.pageThree{
  top: 0px;
  left: 0px;
  width: 100%!important;
  transform:translateY(100%);
  border-top: 4px solid var(--vicGreen);
}
.pageFour{
  top: 0px;
  left: 0px;
  width: 100%!important;
  transform:translateY(100%);
  background-color: #f9f9f9;
  border-top: 4px solid var(--vicGreen);
}
.pageFive{
  top: 0px;
  left: 0px;
  width: 100%!important;
  transform:translateY(100%);
  border-top: 4px solid var(--vicGreen);
}

.endPage{
  top: 0px;
  left: 0px;
  transform:translateX(-100%);
  z-index: 6;
}

.title{
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  top: 12%;
  opacity: 0;
  will-change: opacity;
}

.scrollSign{
  position: absolute;
  width: 180px;
  left:50%;
  bottom :30px;
  transform: translate(-50%,0);
  
  opacity: 0;
  will-change: opacity;
}

.failContainer {
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  height: 90vh;
  z-index: 50;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg);
  color: var(--darkBlue);
  padding: 20px;
  
}

.failContainer p{
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  
}

.vicvLogo{
  position: fixed;
  width: 200px;
  top: 15px;
  left: 15px;
  z-index: 6;
  background-color: #ffffff;
  padding:4px 10px 6px 10px;
  border-radius: 10px;
}
.debugger{
  position: fixed;
  width: 200px;
  top: 65px;
  left: 15px;
  z-index: 6;
  background-color: #ffffff;
  padding:4px 10px 6px 40px;
  border-radius: 10px;
  display: none;
}
.round{
  border-radius: 14px;
  overflow: hidden;
}

.wireButton{
  position: fixed;
  top: 15px;
  right: 20px;
  color: var(--vicGreen);
  background-color: var(--darkBlue);
  border-radius: 15px;
  font-size: 16px;
  border: none;
  padding: 4px 12px 4px 12px;
  display: none;
  z-index: 10;
 
}

.wireButton:hover{
  color: var(--darkBlue);
  background-color: var(--vicGreen);
}

.socials{
  display: flex;
  flex-direction: row;
}
.socialImg{
  box-sizing: border-box;
  width: 26px;
  height: auto;
  display: block;
  background-color: #00000000 ;
  margin-left: 10px;
  border-radius: 6px;
  overflow: hidden;
}